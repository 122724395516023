import React from 'react';
import {graphql, Query} from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../spinner/spinner';
import MarkdownViewer from '../MarkdownViewer';
import elevator from '../../assets/images/elevator.png';
import styles from './elevator_animation.module.scss';

const COMPONENT_QUERY = gql`query sectionComponents {
  components (where: {component_name: "elevator_animation"}) {
    textfield1
    header1
  }
}`;

const elevatorSection = () => (
  <Query query={COMPONENT_QUERY}>
    {({ loading, error, data }) => {
      if (loading) return <Spinner />
      if (error) return `Error! ${error.message}`;

      return (
        <div className={styles.elevatorAnimation}>
          <h1>{data.components[0].header1}</h1>
          <div className={styles.shaft}>
            <div className={styles.elevator}>
              <img src={elevator} className={styles.icon} alt="Elevator"/>
            </div>
          </div>
          <MarkdownViewer className={styles.textField} md={data.components[0].textfield1} />
        </div>
      );
    }}
  </Query>
);


export default graphql(COMPONENT_QUERY)(elevatorSection);