import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './cylnotes.module.scss';

let cx = classNames.bind(styles);

const cylnotes = (props) => {



  const [isOpen, setOpen] = useState(false);

  let arrowClass;
  if (isOpen) {
    arrowClass = cx({
      arrowOpen: true,
    });
  } else {
    arrowClass = cx({
      arrowClose: true,
    });
  }

  let cylnoteHandler = (e) => {
    setOpen(!isOpen);
  }

  return (
    <div className={styles.collapseData}>
      <h3 onClick={cylnoteHandler} className={arrowClass}>Cylinder Notes</h3>
      { isOpen ?
        <div className={styles.dataDiv}>
          <ul>
            {props.data}
          </ul>
        </div>
        : null
      }
    </div>
  );
};

export default cylnotes;