import React, { useState } from 'react';
import MarkdownViewer from '../../../../../../components/MarkdownViewer';
import ImageModal from '../../../../../../components/modals/image/multi_image_modal';
import expand from '../../../../../../assets/images/zoom_icon.png';
import classNames from 'classnames/bind';
import styles from './tool_card.module.scss';

const tool_card = (props) => {

  const [open, setOpen] = useState(false);

  let cx = classNames.bind(styles);
  let imgurl = "https://anchor-api.texacone.com" + props.components.media1.url;

  let imageClick = () => {
    setOpen(!open);
  }


  let cardClass;
  let cxObject = {
    cardLayout: true,
  };
  if (props.components.kind === 'image_left') {
    cxObject.image_left = true;
  }
  if (props.components.kind === 'image_right') {
    cxObject.image_right = true;
  }
  if (props.components.background === 'white') {
    cxObject.white = true;
  }
  if (props.components.background === 'blue') {
    cxObject.blue = true;
  }
  cardClass = cx(cxObject);

  let expandNames;
  let cx1Object = {
    expandButton: true,
  }
  if (props.components.kind === 'image_left') {
    cx1Object.expand_left = true;
  }
  if (props.components.kind === 'image_right') {
    cx1Object.expand_right = true;
  }
  expandNames = cx(cx1Object);


  return (
    <div className={cardClass} id={props.components.header1} value={props.components.header1}>

      <img src={imgurl} alt={props.components.media1.name} />
      <h1>{props.components.header1}</h1>
      <div className={styles.textField}>
        <MarkdownViewer md={props.components.textfield1} />
      </div>
      <ImageModal
        className={styles.modalImage}
        images={props.images}
        open={imageClick}
        opened={open}
        />
      <div className={expandNames} onClick={imageClick}  >
        <img src={expand} alt="expand button"/>
      </div>
    </div>
  );
};

export default tool_card;