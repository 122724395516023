import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Login from './components/modals/Login/Login'
import Header from './components/Header/Header'
import Footer from './components/footer/footer'
import { loadReCaptcha } from 'react-recaptcha-v3'
import Home from './pages/Home/Home'
import Products from './pages/Products/Products'
import AboutUs from './pages/AboutUs/AboutUs'
import Tools from './pages/Products/Tools/Tools'
import Policy from './pages/Policy/Policy'
import IECHI from './pages/IECHI/IECHI'
import Contact from './pages/Contact/Contact'
import Hydraulics from './pages/Products/Hydraulicv2/Hydraulic'
import HydraulicSealingProducts from './pages/Products/ElevatorIndustryv2/ElevatorIndustry'
import Videos from './pages/Videos/Videos'
import TractionParts from './pages/Products/Traction_Parts/traction_parts'
import { Helmet } from 'react-helmet'

class App extends Component {
  componentDidMount() {
    loadReCaptcha('6Lc7SaAUAAAAAI2-XZhO07roB5SupFfg9AIOYqao')
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>Texacone</title>
          <meta
            name="description"
            content="The Texacone Company. Manufacturer of fabric-reinforced elastomer and urethane molded products."
          />
          <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-PVF96TP');`}</script>
        </Helmet>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/products" component={Products} />
          <Route exact path="/about" component={AboutUs} />
          <Route path="/about/:subsection" component={AboutUs} />
          <Route exact path="/tools_and_accessories" component={Tools} />
          <Route exact path="/hydraulic_sealing" component={Hydraulics} />
          <Route
            exact
            path="/elevator_industry"
            component={HydraulicSealingProducts}
          />
          <Route exact path="/videos_and_downloads" component={Videos} />
          <Route
            exact
            path="/tractionmachinepackings"
            component={TractionParts}
          />
          <Route exact path="/texhead" component={IECHI} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/policy" component={Policy} />
          <Route exact path="/login" component={Login} />
        </Switch>
        <Footer />
      </div>
    )
  }
}

export default App
