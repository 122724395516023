import React from 'react';
import {graphql, Query} from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../../../../components/spinner/spinner';
import Whirligig from 'react-whirligig';
import VideoCard from '../../../../../components/video_card/video_card';
import leftArrow from '../../../../../assets/images/Shape.png';
import rightArrow from '../../../../../assets/images/Shape 2.png';
import styles from './scroller.module.scss';


const COMPONENT_QUERY = gql`query sectionComponents {
  sections (where: { section_name: "videos", page_name: "products" }) {
    header1
    description1
    components {
      component_name
      _id
      textfield1
      textfield2
      header1
      header2
      media1 {
        url
        name
      }
      media2 {
        url
        name
      }
      media3 {
        url
        name
      }
    }
  }
}`;

const scroller = ({ slideIndex }) => (

  <Query query={COMPONENT_QUERY}>
    {({ loading, error, data }) => {
      if (loading) return <Spinner />
      if (error) return `Error! ${error.message}`;
      let whirligig;
      const next = () => whirligig.next();
      const prev = () => whirligig.prev();



      let components = data.sections[0].components;
      let cards = components.map(card => {
        return (
          <VideoCard
            id={card._id}
            key={card._id}
            components={card}
            class={card._id}
            />
            );
        });



      return (
        <div className={styles.scrollerBar}>
          <div className={styles.button} onClick={prev}><img src={leftArrow} alt="LeftArrow" /></div>
          <Whirligig
            visibleSlides={3}
            slideBy={1}
            gutter="5px"
            animationDuration={750}
            easing={(t) =>t}
            infinite={false}
            ref={(_whirligigInstance) => { whirligig = _whirligigInstance}}
          >
            {cards}
          </Whirligig>
          <div className={styles.button} onClick={next}><img src={rightArrow} alt="RightArrow" /></div>
        </div>
      );
    }}
  </Query>
);



export default graphql(COMPONENT_QUERY)(scroller);
