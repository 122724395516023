import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './packinfo.module.scss';

let cx = classNames.bind(styles);


const packinfo = (props) => {

  const [isOpen, setOpen] = useState(false);

  let arrowClass;
  if (isOpen) {
    arrowClass = cx({
      arrowOpen: true,
    });
  } else {
    arrowClass = cx({
      arrowClose: true,
    });
  }

  let packinfoHandler = (e) => {
    setOpen(!isOpen);
  }

  return (
    <div className={styles.collapseData}>
        <h3 onClick={packinfoHandler} className={arrowClass}>Packing Set Info</h3>
        {isOpen ?
          <div className={styles.dataDiv}>
            <table>
              <thead>
                <tr>
                  <th>
                    Plunger Diameter
                  </th>
                  <th>
                    Type
                  </th>
                  <th>
                    Nominal
                  </th>
                  <th>
                    Packing
                  </th>
                  <th>
                    Packing Set Number
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.data}
              </tbody>
            </table>
          </div>
          : null
        }
      </div>
  );
};

export default packinfo;