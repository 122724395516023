import React, { Component } from 'react';
import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../components/spinner/spinner';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import MarkdownViewer from '../../components/MarkdownViewer';
import { Helmet } from 'react-helmet';
import facebookLogo from '../../assets/images/facebook-grey.svg';
import linkedinLogo from '../../assets/images/linkedin_icon_grey.svg';
import pinLogo from '../../assets/images/Pin_icon.svg';
import phoneLogo from '../../assets/images/Phone_icon.svg';
import mailLogo from '../../assets/images/mail_icon.svg';
import styles from './Contact.module.scss';

class Contact extends Component {
  render() {
    const SECTIONS_QUERY = gql`query sectionComponents {
      sections (where: { section_name: "contact", page_name: "contact" }) {
        header1
        description1
        media1 {
          url
          name
        }
      }
    }`;


    return (


      <Query query={SECTIONS_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <Spinner />
          if (error) return `Error! ${error.message}`;

          let imgurl = "https://anchor-api.texacone.com" + data.sections[0].media1.url
          let inlinestyles = {
            backgroundImage: `linear-gradient(rgba(0,0,0,0.25), rgba(0,0,0,0.25)), url(${imgurl})`,
            backgroundSize: 'cover, cover',
            backgroundRepeat: 'no-repeat'
          };

          return (
          <div className={styles.contactWrapper}>
            <Helmet>
              <title>Contact Us | Texacone | Talk to an Expert</title>
              <meta name="description" content="Have a question or comment? Need sales assistance or help identifying a part or problem? We're standing by to help you now!" />
            </Helmet>
            <ScrollToTop />
            <div className={styles.leftSide} style={inlinestyles}>
              <h1>{data.sections[0].header1}</h1>
            </div>
            <div className={styles.rightSide}>
              <div className={styles.rightSection}>
                <MarkdownViewer md={data.sections[0].description1} />
                <div className={styles.textBlock}>
                  <a href="https://goo.gl/maps/UkvVNFd5rCG2" target="_blank" rel="noreferrer noopener">
                    <img src={pinLogo} alt="Pin Icon" />
                  </a>
                  <div className={styles.innerInfo}>
                    <p>Address</p>
                    <a href="https://goo.gl/maps/UkvVNFd5rCG2" target="_blank" rel="noreferrer noopener">4111 Forney Avenue</a>
                    <a href="https://goo.gl/maps/UkvVNFd5rCG2" target="_blank" rel="noreferrer noopener">Mesquite, Texas 75149 USA</a>
                  </div>
                  <a href="tel:9722884404">
                    <img src={phoneLogo} alt="Phone icon" />
                  </a>
                  <div className={styles.innerInfo}>
                    <p>Telephone</p>
                    <a href="tel:9722884404">(972) 288-4404</a>
                  </div>
                  <a href="mailto:info.tex@hyperion-solutions.comnfo@texacone.com">
                    <img src={mailLogo} alt="Mail Logo"/>
                  </a>
                  <div className={styles.innerInfo}>
                    <p>E-mail</p>
                    <a href="mailto:info.tex@hyperion-solutions.com">info.tex@hyperion-solutions.com</a>
                  </div>
                  <a href="https://www.facebook.com/Texacone/">
                    <img src={facebookLogo} alt="FacebookLogo" />
                  </a>
                  <div className={styles.innerInfo}>
                    <a href="https://www.facebook.com/Texacone/">Like us on Facebook.</a>
                  </div>
                  <a href="https://www.linkedin.com/company/the-texacone-company/">
                    <img src={linkedinLogo} alt="LinkedinLogo" />
                  </a>
                  <div className={styles.innerInfo}>
                    <a href="https://www.linkedin.com/company/the-texacone-company/">Follow us on LinkedIn.</a>
                  </div>
                  {/* <div className={styles.innerInfo}>
                    <p>Sign up here for emails from Texacone.</p>
                    <button className={styles.signupButton}><span>SIGN UP</span></button>
                  </div> */}
                </div>
              </div>
            </div>

          </div>
          );
        }}
      </Query>
      )
  }
}

export default Contact;
