import React from 'react';
import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../../../../components/spinner/spinner';
import Card from './ei_card/ei_card';
import EiSelection from '../ei_selection/ei_selection';
import styles from './ei_section.module.scss';

const COMPONENT_QUERY = gql`query pageProducts {
  pages (where: { page_name: "elevator_industry" }) {
    products (sort: "order:asc") {
      _id
      product_name
      kind
      background
      header1
      header2
      textfield1
      media1 {
        url
        name
      }
      media2 {
        url
        name
      }
      media3 {
        url
        name
      }
      media4 {
        url
        name
      }

    }
  }
}`;

const toolsection = () => (
  <Query query={COMPONENT_QUERY}>
    {({ loading, error, data }) => {
      if (loading) return <Spinner />
      if (error) return `Error! ${error.message}`;
      let products = data.pages[0].products;
      let cardHeaders = products.map(card => card.header1);
      let cards = products.map(card => {
        let images = [];
        if (card.media1) {
          let image0 = card.media1;
          images.push(image0);
        }
        if (card.media2) {
          let image1 = card.media2;
          images.push(image1);
        }
        if (card.media3) {
          let image2 = card.media3;
          images.push(image2);
        }
        if (card.media4) {
          let image3 = card.media4;
          images.push(image3);
        }

        return (
          <Card
            id={card._id}
            key={card._id}
            components={card}
            images={images}
            />
            );
        });


      return (
        <div className={styles.toolWrapper}>
          <EiSelection cardHeaders={cardHeaders} />
          {cards}
        </div>
      );
    }}
  </Query>
);

export default toolsection;