import React, { useState } from 'react'
import axios from 'axios'
import { ReCaptcha } from 'react-recaptcha-v3'
import facebook from '../../assets/images/facebook.png'
import linkedin from '../../assets/images/linkedin.png'
import { Link } from 'react-router-dom'
import flag from '../../assets/images/flag.png'

import styles from './footer.module.scss'

const footer = () => {
  let newToken

  let verifyCallback = recaptchaToken => {
    newToken = recaptchaToken
  }

  const [email, setEmail] = useState('')
  const [emailSuccess, setEmailSuccess] = useState(false)
  const [emailError, setEmailError] = useState(false)

  const apiEndpoint = `https://www.texacone.com/texapi/email/?email=${email}`

  let handleChange = event => {
    setEmail(event.target.value)
  }

  let handleSubmit = async event => {
    setEmailError(false)
    setEmailSuccess(false)
    event.preventDefault()
    axios({ method: 'GET', url: apiEndpoint })
      .then(function(response) {
        setEmailSuccess(true)
      })
      .catch(function(error) {
        setEmailError(true)
      })
    setEmail('')
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.col}>
        <h3 className={styles.follow}>FOLLOW US</h3>
        <a
          href="https://www.facebook.com/Texacone/"
          className={styles.facebookLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={facebook} alt="facebook" className={styles.facebookIcon} />
          <p>on Facebook</p>
        </a>
        <a
          href="https://www.linkedin.com/company/the-texacone-company/"
          className={styles.linkedinLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={linkedin} alt="facebook" className={styles.linkedinIcon} />
          <p>on LinkedIn</p>
        </a>
      </div>
      <div className={styles.col + ' ' + styles.sign}>
        <form onSubmit={handleSubmit}>
          <h3>SIGN UP FOR OUR EMAIL</h3>
          <input type="email" value={email} onChange={handleChange} />
          <button>Submit</button>
          {emailError ? (
            <div className={styles.errorDiv}>There's an error!</div>
          ) : null}
          {emailSuccess ? (
            <div className={styles.successDiv}>Email successfully added</div>
          ) : null}
        </form>
      </div>
      <div className={styles.col + ' ' + styles.contact}>
        <h3>CONTACT US</h3>
        <a href="tel:1-972-288-4404">(972) 288-4404</a>
        <a href="mailto:info.tex@hyperion-solutions.com">info.tex@hyperion-solutions.com</a>
      </div>
      <div className={styles.links}>
        <Link to="/texhead">CYLINDER HEAD ID</Link>
        <Link to="/products">PRODUCTS</Link>
        <Link to="/about">ABOUT</Link>
        <Link to="/login">LOGIN & REGISTER</Link>
      </div>
      <div className={styles.bottomLogos}>
        <img src={flag} alt="USFlag" className={styles.murrica} />
      </div>
      <small className={styles.policy}>
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">
          Terms of Service
        </a> apply.{' '}
        <a href="https://www.texacone.com/policy">
          Click here for Texacone Privacy Policy
        </a>
      </small>
      <p className={styles.copyright}>
        &copy; 1997-2019 The Texacone Company, all rights reserved.
      </p>
      <ReCaptcha
        sitekey="6Lc7SaAUAAAAAI2-XZhO07roB5SupFfg9AIOYqao"
        verifyCallback={verifyCallback}
      />
    </footer>
  )
}

export default footer
