import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './packalt.module.scss';

let cx = classNames.bind(styles);

const packalt = (props) => {


  const [isOpen, setOpen] = useState(false);

  let arrowClass;
  if (isOpen) {
    arrowClass = cx({
      arrowOpen: true,
    });
  } else {
    arrowClass = cx({
      arrowClose: true,
    });
  }

  let packaltHandler = (e) => {
    setOpen(!isOpen);
  }

  return (
    <React.Fragment>
      <div className={styles.collapseData}>
        <h3 onClick={packaltHandler} className={arrowClass}>Packing Alternatives</h3>
        { isOpen ?
          <div className={styles.dataDiv}>
          <table>
            <thead>
              <tr>
                <th>
                  Condition
                </th>
                <th>
                </th>
                <th>
                  Recommendation
                </th>
              </tr>
            </thead>
            <tbody>
              {props.data}
            </tbody>
          </table>
        </div>
          : null
        }
      </div>
    </React.Fragment>
  );
};

export default packalt;