import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import classNames from 'classnames/bind'
import CustomRadio from './custradio/custradio'
import styles from './form.module.scss'

export default function App() {
  const [show, setShow] = useState(false)
  let newDate = new Date()
  let day = newDate.getDate()

  let month = newDate.getMonth() + 1
  let year = newDate.getFullYear()

  let currentDate = `${year}/${month < 10 ? `0${month}` : `${month}`}/${
    day < 10 ? `0${day}` : `${day}`
  }`
  let cx = classNames.bind(styles)

  let modalStyle
  if (show) {
    modalStyle = cx({
      formmodal: true,
      show: true,
    })
  } else {
    modalStyle = cx({
      formmodal: true,
    })
  }

  const { register, handleSubmit, errors, reset } = useForm()

  let clearModal = () => {
    setShow(false)
    reset()
  }

  let onSubmit = (data, event) => {
    let totalKeys = Object.keys(data)
    totalKeys.forEach(key => {
      if (data[key] === '') {
        delete data[key]
      }
    })
    let formattedData = {}
    if (data['honey_bot']) {
      formattedData['honey_bot'] = data['honey_bot']
    }
    formattedData['Customer_Number'] = data['customer_number']
    formattedData['email'] = data['email']
    let contactInformation = {
      Company_Name: data['company_name'],
      Contact_Name: data['contact_name'],
      Phone: data['phone'],
    }
    formattedData['Contact_Information'] = contactInformation
    let billingInformation = {
      Billing_Address: data['billing_address'],
      City: data['billing_city'],
      State: data['billing_state'],
      Zip: data['billing_zip_code'],
    }
    formattedData['Billing_Information'] = billingInformation
    let shippingInformation = {
      Shipping_To: data['shipping_to'],
      Shipping_Address: data['shipping_address'],
      City: data['shipping_city'],
      State: data['shipping_state'],
      Zip: data['shipping_zip_code'],
      Drop_Ship: data['drop_ship'],
      Ship_Method: data['ship_method'],
      Account: data['account'],
      PO_Number: data['po_number'],
    }
    formattedData['Shipping_Information'] = shippingInformation
    // console.log(form)
    for (let i = 1; i < 11; i++) {
      if (`part_${i}` in data) {
        let newPart = {
          part_number: data[`part_${i}`],
          part_qty: data[`part_${i}_qty`],
          part_date: data[`part_${i}_date`],
        }
        formattedData[`part${i}`] = newPart
      }
    }

    formattedData['comments'] = data['comments']

    console.log(formattedData)
    event.preventDefault()
    axios({
      headers: { 'Content-Type': 'application/json' },
      url: 'https://usebasin.com/f/1dee3fa2da6e',
      method: 'post',
      data: formattedData,
    })
      .then(response => {
        console.log(response)
        setShow(true)
      })
      .catch(error => {
        console.log('error was' + error)
      })
  }

  // <form className={styles.orderform} onSubmit={handleSubmit(onSubmit)}>
  //       <input name="example" defaultValue="test" ref={register} />
  //       <input name="exampleRequired" ref={register({ required: true })} />
  //       {errors.exampleRequired && <span>This field is required</span>}

  //       <input type="submit" />
  //     </form>
  return (
    <div id="order-form" className={styles.orderformcontainer}>
      <div className={modalStyle} onClick={clearModal}>
        <h3>
          Your order has been successfully submitted. Someone will reach out to
          you with 48 hours. Click to close and reset form.
        </h3>
      </div>
      <h1>ORDER REQUEST</h1>
      <div className={styles.orderformwrapper}>
        <form className={styles.orderform} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.columnone}>
            <label htmlFor="customer_number">Customer # (If Known) : </label>
            <input
              type="text"
              className={styles.col2}
              name="customer_number"
              ref={register}
            />
            <label htmlFor="company_name" className={styles.col1}>
              Company Name :
            </label>
            <input
              type="text"
              className={styles.long}
              name="company_name"
              ref={register}
            />
            <label htmlFor="contact_name">Contact Name : </label>
            <input
              type="text"
              className={styles.long}
              name="contact_name"
              style={errors.contact_name && { background: 'pink' }}
              placeholder={
                errors.contact_name ? 'This field is required' : null
              }
              ref={register({
                required: true,
              })}
            />
            <label htmlFor="phone">Phone # : </label>
            <input
              type="tel"
              className={styles.long}
              name="phone"
              style={errors.phone && { background: 'pink' }}
              placeholder={errors.phone ? 'This field is required' : null}
              ref={register({
                required: true,
              })}
            />
            <label htmlFor="email">Email Address : </label>
            <input
              type="email"
              className={styles.long}
              name="email"
              style={errors.email && { background: 'pink' }}
              placeholder={errors.email ? 'This field is required' : null}
              ref={register({
                required: true,
              })}
            />
            <label htmlFor="billing_address">Billing Address : </label>
            <input
              type="text"
              className={styles.long}
              style={errors.billing_address && { background: 'pink' }}
              name="billing_address"
              ref={register({
                required: true,
              })}
              placeholder={
                errors.billing_address ? 'This field is required' : null
              }
            />
            <label htmlFor="billing_city">City : </label>
            <input
              type="text"
              className={styles.long}
              name="billing_city"
              style={errors.billing_city && { background: 'pink' }}
              placeholder={
                errors.billing_city ? 'This field is required' : null
              }
              ref={register({
                required: true,
              })}
            />
            <label htmlFor="billing_state">State : </label>
            <input
              type="text"
              className={styles.col2}
              name="billing_state"
              style={errors.billing_state && { background: 'pink' }}
              placeholder={
                errors.billing_state ? 'This field is required' : null
              }
              ref={register({
                required: true,
              })}
            />
            <label htmlFor="billing_zip_code" className={styles.col3}>
              Zip :
            </label>
            <input
              ref={register({
                required: true,
              })}
              style={errors.billing_zip_code && { background: 'pink' }}
              placeholder={
                errors.billing_zip_code ? 'This field is required' : null
              }
              type="number"
              className={styles.col4}
              name="billing_zip_code"
            />
            <label htmlFor="shipping_to">Ship To Name : </label>
            <input
              ref={register({
                required: true,
              })}
              type="text"
              className={styles.long}
              name="shipping_to"
              style={errors.shipping_to && { background: 'pink' }}
              placeholder={errors.shipping_to ? 'This field is required' : null}
            />
            <label htmlFor="shipping_address">Shipping Address : </label>
            <input
              ref={register({
                required: true,
              })}
              type="text"
              className={styles.long}
              name="shipping_address"
              style={errors.shipping_address && { background: 'pink' }}
              placeholder={
                errors.shipping_address ? 'This field is required' : null
              }
            />
            <label htmlFor="shipping_city">City : </label>
            <input
              type="text"
              className={styles.long}
              name="shipping_city"
              style={errors.shipping_city && { background: 'pink' }}
              placeholder={
                errors.shipping_city ? 'This field is required' : null
              }
              ref={register({
                required: true,
              })}
            />
            <label htmlFor="shipping_state">State : </label>
            <input
              type="text"
              className={styles.col2}
              name="shipping_state"
              style={errors.shipping_state && { background: 'pink' }}
              placeholder={
                errors.shipping_state ? 'This field is required' : null
              }
              ref={register({
                required: true,
              })}
            />
            <label htmlFor="shipping_zip_code" className={styles.col3}>
              Zip :
            </label>
            <input
              ref={register({
                required: true,
              })}
              style={errors.shipping_zip_code && { background: 'pink' }}
              placeholder={errors.zip_code ? 'This field is required' : null}
              type="number"
              className={styles.col4}
              name="shipping_zip_code"
            />
            <label>Is this a drop ship?</label>
            <div className={styles.selectorlong}>
              <CustomRadio
                unique="radio_1"
                label="Yes"
                value="yes"
                register={register}
              />
              <CustomRadio
                unique="radio_2"
                label="No"
                value="no"
                register={register}
              />
            </div>
            <label htmlFor="ship_method" className={styles.col1}>
              Ship Method :
            </label>
            <input
              type="text"
              className={styles.long}
              name="ship_method"
              ref={register}
            />
            <label htmlFor="account">Account : </label>
            <input
              type="text"
              className={styles.long}
              name="account"
              ref={register}
            />
            <label htmlFor="po_number">PO#: </label>
            <input
              type="text"
              className={styles.long}
              name="po_number"
              ref={register}
            />
            <div className={styles.bottompara}>
              <p>
                *Please note that submittal of this form does not guarantee an
                order or a delivery date. Once Texacone has received your form,
                we will process the order and confirm all details back to you
                within 24 business hours.
              </p>
            </div>
          </div>

          <div className={styles.columntwo}>
            <div className={styles.partform}>
              <label>Part #</label>
              <label>Qty</label>
              <label>Need Date</label>
              <input
                type="text"
                name="part_1"
                ref={register}
                placeholder="Part # 1"
              />
              <input
                type="number"
                name="part_1_qty"
                ref={register}
                placeholder="Quantity"
              />
              <input
                type="date"
                min={currentDate}
                name="part_1_date"
                ref={register}
              />
              <input
                type="text"
                name="part_2"
                ref={register}
                placeholder="Part # 2"
              />
              <input
                type="number"
                name="part_2_qty"
                ref={register}
                placeholder="Quantity"
              />
              <input
                type="date"
                min={currentDate}
                name="part_2_date"
                ref={register}
              />
              <input
                type="text"
                name="part_3"
                ref={register}
                placeholder="Part # 3"
              />
              <input
                type="number"
                name="part_3_qty"
                ref={register}
                placeholder="Quantity"
              />
              <input
                type="date"
                min={currentDate}
                name="part_3_date"
                ref={register}
              />
              <input
                type="text"
                name="part_4"
                ref={register}
                placeholder="Part # 4"
              />
              <input
                type="number"
                name="part_4_qty"
                ref={register}
                placeholder="Quantity"
              />
              <input
                type="date"
                min={currentDate}
                name="part_4_date"
                ref={register}
              />
              <input
                type="text"
                name="part_5"
                ref={register}
                placeholder="Part # 5"
              />
              <input
                type="number"
                name="part_5_qty"
                ref={register}
                placeholder="Quantity"
              />
              <input
                type="date"
                min={currentDate}
                name="part_5_date"
                ref={register}
              />
              {/* <input type="text" name="part_6" ref={register} />
              <input type="number" name="part_6_qty" ref={register} />
              <input
                type="date"
                min={currentDate}
                name="part_6_date"
                ref={register}
              />
              <input type="text" name="part_7" ref={register} />
              <input type="number" name="part_7_qty" ref={register} />
              <input
                type="date"
                min={currentDate}
                name="part_7_date"
                ref={register}
              />
              <input type="text" name="part_8" ref={register} />
              <input type="number" name="part_8_qty" ref={register} />
              <input
                type="date"
                min={currentDate}
                name="part_8_date"
                ref={register}
              />
              <input type="text" name="part_9" ref={register} />
              <input type="number" name="part_9_qty" ref={register} />
              <input
                type="date"
                min={currentDate}
                name="part_9_date"
                ref={register}
              />
              <input type="text" name="part_10" ref={register} />
              <input type="number" name="part_10_qty" ref={register} />
              <input
                type="date"
                min={currentDate}
                name="part_10_date"
                ref={register}
              /> */}
            </div>
            <p>Comments</p>
            <textarea
              ref={register}
              name="comments"
              className={styles.commentfield}
            ></textarea>
            <input type="text" name="honey_bot" style={{ display: 'none' }} />
            <div className={styles.buttondiv}>
              <input type="submit" ref={register} value="Submit" />
              {/* <input type="reset" className={styles.clearbutton} /> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
