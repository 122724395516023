import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './packnotes.module.scss';

let cx = classNames.bind(styles);

const packnotes = (props) => {


  const [isOpen, setOpen] = useState(false);

  let arrowClass;
  if (isOpen) {
    arrowClass = cx({
      arrowOpen: true,
    });
  } else {
    arrowClass = cx({
      arrowClose: true,
    });
  }

  let collapseClass;
  if (isOpen) {
    collapseClass = cx({
      collapseData: true,
    });
  } else {
    collapseClass = cx({
      collapseData: true,
      collapsed: true,
    });
  }

  let packnoteHandler = (e) => {
    setOpen(!isOpen);
  }

  return (
      <div className={collapseClass}>
        <h3 onClick={packnoteHandler} className={arrowClass}>Packing Notes</h3>
        { isOpen ?
          <div className={styles.dataDiv}>
            <ul>
              {props.data}
            </ul>
          </div>
          : null
        }
      </div>
  );
};

export default packnotes;