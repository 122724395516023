import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './packcont.module.scss';

let cx = classNames.bind(styles);

const packcont = (props) => {


  const [isOpen, setOpen] = useState(false);

  let arrowClass;
  if (isOpen) {
    arrowClass = cx({
      arrowOpen: true,
    });
  } else {
    arrowClass = cx({
      arrowClose: true,
    });
  }

  let packcontHandler = (e) => {
    setOpen(!isOpen);
  }

  return (
    <React.Fragment>
      <div className={styles.collapseData}>
        <h3 onClick={packcontHandler} className={arrowClass}>Packing Set Contents</h3>
        { isOpen ?
          <div className={styles.dataDiv}>
            <div className={styles.contentImages}>
              {props.data}
            </div>
          </div>
          : null
        }
      </div>
    </React.Fragment>
  );
};

export default packcont;