import React from 'react';
import {graphql, Query} from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../../../components/spinner/spinner';
import ProductOption from './product_options/product_option';
import Elevator from '../../../../components/elevator_animation/elevator_animation';
import styles from './product_choice.module.scss';


const COMPONENT_QUERY = gql`query sectionComponents {
  sections (where: { section_name: "product_choice", page_name: "products" }) {
    header1
    description1
    header2
    media1 {
      url
      name
    }
    components (sort: "component_name:asc"){
      component_name
      _id
      textfield1
      textfield2
      header1
      header2
      media1 {
        url
        name
      }
      media2 {
        url
        name
      }
      media3 {
        url
        name
      }
    }
  }
}`;


const pcsection = () => (
  <Query query={COMPONENT_QUERY}>
    {({ loading, error, data }) => {
      if (loading) return <Spinner />
      if (error) return `Error! ${error.message}`;
      let components = data.sections[0].components;
      let logourl = "https://anchor-api.texacone.com" + data.sections[0].media1.url
      let block_options = components.map(card => {
        return (
          <ProductOption
            id={card._id}
            key={card._id}
            components={card}
            />
            );
        });


      return (
        <div className={styles.pcWrapper}>
          <div className={styles.productsBanner}>
            <img src={logourl} alt={data.sections[0].media1.name}/>
            <h1>{data.sections[0].header1}</h1>
          </div>
          <div className={styles.productBlock}>
            {block_options}
          </div>
          <Elevator />
        </div>
      );
    }}
  </Query>
);


export default graphql(COMPONENT_QUERY)(pcsection);
