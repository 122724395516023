import React, { useState } from 'react';
import expand from '../../../assets/images/close-button.png';
import styles from './multi_image_modal.module.scss';

const image_modal = (props) => {


  let initialImage;

  if (props.images.length > 1) {
    initialImage = {image: "https://anchor-api.texacone.com" + props.images[1].url, name: props.images[1].name}
  } else {
    initialImage = {image: "https://anchor-api.texacone.com" + props.images[0].url, name: props.images[0].name}
  }

  const [img, setImg] = useState(initialImage);

  let handleImgClick = (e) => {
    e.stopPropagation();
    let image = {image: "https://anchor-api.texacone.com" + e.target.getAttribute('data-tag'), name: e.target.alt};
    setImg(image);
  }

  let alteredImages = [...props.images];
  alteredImages.shift();

  let images = alteredImages.map(image => {
    let innerimgUrl = "https://anchor-api.texacone.com" + image.url;
    return(
      <div className={styles.selectImg} key={image.name}>
        <img src={innerimgUrl} alt={image.name} data-tag={image.url} onClick={handleImgClick}/>
      </div>
    )
  });

  if(!props.opened) {
    return null;
  } else {
    return (
      <div className={styles.imageWrapper} onClick={props.open}>
        <div className={styles.innerWrapper}>
          <div className={styles.backButton} onClick={props.open}>
            <img src={expand} alt="expand button" />
          </div>
          <img className={styles.modalImg} src={img.image} alt={img.name} />
          {images.length > 1 &&
            <div className={styles.modalSelect}>
              {images}
            </div>
          }
        </div>
      </div>
    );
  }
};

export default image_modal;