import React from 'react';
import {graphql, Query} from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../../../components/spinner/spinner';
import MarkdownViewer from '../../../../components/MarkdownViewer';
import styles from './phone.module.scss';


const COMPONENT_QUERY = gql`query sectionComponents {
  sections (where: { section_name: "phone", page_name: "home" }) {
    header1
    description1
    media1 {
      url
      name
    }
    components {
      component_name
      _id
      textfield1
      textfield2
      header1
      header2
      media1 {
        url
        name
      }
      media2 {
        url
        name
      }
      media3 {
        url
        name
      }
    }
  }
}`;



const phoneSection = () => (
  <Query query={COMPONENT_QUERY}>
    {({ loading, error, data }) => {
      if (loading) return <Spinner />
      if (error) return `Error! ${error.message}`;
      let components = data.sections[0].components;
      let topper = components.filter(obj => {
        return obj.component_name === 'phone_section_top_image'
      });
      let social = components.filter(obj => {
        return obj.component_name === 'phone_section_social'
      });
      let description = components.filter(obj => {
        return obj.component_name === 'phone_section_description_text'
      });



      return (
        <div className={styles.sectionCard}>
          <div className={styles.leftField}>
            <h1>{data.sections[0].header1}</h1>
            <div className={styles.socialIcons}>
              <a href="/iechi"><img src={"https://anchor-api.texacone.com" + social[0].media1.url} alt={social[0].media1.name} className={styles.social1} /></a>
              <a href="https://itunes.apple.com/us/app/texacone-on-site/id1131678695?mt=8" target="_blank" rel="noopener noreferrer"><img src={"https://anchor-api.texacone.com" + social[0].media2.url} alt={social[0].media2.name} className={styles.social2} /></a>
              <a href="https://play.google.com/store/apps/details?id=com.cratebind.texacone&hl=en_US" target="_blank" rel="noopener noreferrer"><img src={"https://anchor-api.texacone.com" + social[0].media3.url} alt={social[0].media3.name} className={styles.social3} /></a>
            </div>
          </div>
          <div className={styles.rightField}>
            <div className={styles.phoneContainer}>
              <div className={styles.phoneDetail}>
                <img src={"https://anchor-api.texacone.com" + topper[0].media1.url} alt={topper[0].media1.name} className={styles.phoneTopper}/>
                <div className={styles.phoneText}>
                  <MarkdownViewer md={description[0].textfield1}/>
                </div>
                <img className={styles.phoneImage} src={"https://anchor-api.texacone.com" + data.sections[0].media1.url} alt={data.sections[0].media1.name}/>
              </div>
            </div>
          </div>
        </div>
      );
    }}
  </Query>
);


export default graphql(COMPONENT_QUERY)(phoneSection);
