import React, { Component } from 'react'
import axios from 'axios'
import classNames from 'classnames/bind'
import Topper from './topper/topper'
import Spinner from '../../components/spinner/spinner'
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop'
import HeadData from './headdata/headdata'
import Head from './head/head'
import FilterSection from './filtersection/filtersection'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Checkbox from './checkbox/checkbox'
import styles from './IECHI.module.scss'

let cx = classNames.bind(styles)
const s = require('searchjs')

class IECHI extends Component {
  state = {
    data: [],
    filters: [],
    filteredData: [],
    attributeIds: [],
    manufacturers: [],
    activeId: 0,
    currentHead: null,
    currentHeadIndex: null,
    titleHead: 'Cylinder Head',
    partNumber: '',
    isLoading: true,
    iechierror: false,
    msElapsed: 0,
  }

  timeIncrementMs = 50
  showSpinnerIfReturnGreaterThanMs = 200

  stateFilter = () => {
    let filteredData = [...this.state.data]

    this.state.filters.forEach(filter => {
      filteredData = s.matchArray(filteredData, {
        Attribute_Id: filter,
        _propertySearch: true,
      })
    })

    this.setState({ filteredData: filteredData })
  }

  toggleCheckbox = event => {
    if (event.target.checked) {
      let newFilter = [...this.state.filters]
      newFilter.push(parseInt(event.target.value))
      this.setState({ filters: newFilter }, function() {
        this.stateFilter()
      })
    } else {
      let newFilter = [...this.state.filters]
      let index = newFilter.indexOf(parseInt(event.target.value))
      newFilter.splice(index, 1)
      this.setState({ filters: newFilter }, function() {
        this.stateFilter()
      })
    }
  }

  chooseSelect = e => {
    let filteredDatastate = [...this.state.data]

    let filteredData = s.matchArray(filteredDatastate, {
      ManufacturerName: e.target.value,
      _propertySearch: true,
    })

    this.setState({ filteredData: filteredData })
  }

  openHead = (data, index) => {
    let head = data
    this.setState({ currentHead: head, currentHeadIndex: index })
  }

  closeHead = () => {
    this.setState({ currentHead: null })
  }

  hoverHead = headname => {
    let head = headname
    this.setState({ titleHead: head })
  }

  setActiveElement(e, id) {
    e.stopPropagation()
    this.setState({ activeId: id, filters: [], filteredData: this.state.data })
  }

  filterPartNumber = part => {
    let filteredDatastate = [...this.state.data]
    let filteredData = s.matchArray(filteredDatastate, {
      PackingSetNumber: this.state.partNumber,
      _text: true,
      _propertySearch: true,
    })
    this.setState({ filteredData: filteredData })
  }

  handlePartNumber = e => {
    e.preventDefault()
    let part = e.target.value
    this.setState({ partNumber: part }, () =>
      this.filterPartNumber(this.state.partNumber)
    )
  }

  clearFilter = e => {
    e.preventDefault()
    window.location.reload()
  }

  logout = () => {
    localStorage.setItem('isLogged', false)
  }

  componentWillUnmount() {
    clearInterval(this.incrementer)
  }

  componentWillMount() {
    this.incrementer = setInterval(
      () =>
        this.setState({
          msElapsed: this.state.msElapsed + this.timeIncrementMs,
        }),
      this.timeIncrementMs
    )
    axios
      .get('https://www.texacone.com/iechi/api/getheads', {
        headers: {},
      })
      .then(response => {
        this.setState({ data: response.data, isLoading: false }, () =>
          this.stateFilter()
        )
        return axios.get('https://www.texacone.com/iechi/api/getattributes')
      })
      .then(response => {
        this.setState({ attributeIds: response.data })
        return axios.get('https://www.texacone.com/iechi/api/getmanufacturers')
      })
      .then(response => {
        this.setState({ manufacturers: response.data })
      })
      .catch(error => {
        this.setState({ iechierror: true })
      })
  }

  render() {
    let unflatHeads = this.state.filteredData.map(head => {
      let name = head.Name
      let manufacturerObject = head
      let cards = head.Drawings.map((card, index) => {
        return (
          <Head
            data={card}
            index={index}
            totalData={manufacturerObject}
            key={card.Id}
            name={name}
            openHead={this.openHead}
            hoverHead={this.hoverHead}
          />
        )
      })
      return cards
    })

    let heads
    if (unflatHeads.length > 0) {
      heads = unflatHeads.reduce((prev, curr) => {
        return prev.concat(curr)
      })
    }

    // Filter sections of checkboxes

    const features = this.state.attributeIds
      .filter(attribute => attribute.Category_Id === 1)
      .map(key => {
        return (
          <Checkbox
            key={key.Id}
            value={key.Id}
            label={key.Attribute}
            boxchecked={this.toggleCheckbox}
          />
        )
      })
    const configurations = this.state.attributeIds
      .filter(attribute => attribute.Category_Id === 2)
      .map(key => {
        return (
          <Checkbox
            key={key.Id}
            value={key.Id}
            label={key.Attribute}
            boxchecked={this.toggleCheckbox}
          />
        )
      })
    const fastenings = this.state.attributeIds
      .filter(attribute => attribute.Category_Id === 3)
      .map(key => {
        return (
          <Checkbox
            key={key.Id}
            value={key.Id}
            label={key.Attribute}
            boxchecked={this.toggleCheckbox}
          />
        )
      })
    const shapes = this.state.attributeIds
      .filter(attribute => attribute.Category_Id === 4)
      .map(key => {
        return (
          <Checkbox
            key={key.Id}
            value={key.Id}
            label={key.Attribute}
            boxchecked={this.toggleCheckbox}
          />
        )
      })
    const cylinder_types = this.state.attributeIds
      .filter(attribute => attribute.Category_Id === 5)
      .map(key => {
        return (
          <Checkbox
            key={key.Id}
            value={key.Id}
            label={key.Attribute}
            boxchecked={this.toggleCheckbox}
          />
        )
      })
    const units = this.state.attributeIds
      .filter(attribute => attribute.Category_Id === 6)
      .map(key => {
        return (
          <Checkbox
            key={key.Id}
            value={key.Id}
            label={key.Attribute}
            boxchecked={this.toggleCheckbox}
          />
        )
      })
    const materials = this.state.attributeIds
      .filter(attribute => attribute.Category_Id === 7)
      .map(key => {
        return (
          <Checkbox
            key={key.Id}
            value={key.Id}
            label={key.Attribute}
            boxchecked={this.toggleCheckbox}
          />
        )
      })
    const dates = this.state.attributeIds
      .filter(attribute => attribute.Category_Id === 11)
      .map(key => {
        return (
          <Checkbox
            key={key.Id}
            value={key.Id}
            label={key.Attribute}
            boxchecked={this.toggleCheckbox}
          />
        )
      })
    const zones = this.state.attributeIds
      .filter(attribute => attribute.Category_Id === 12)
      .map(key => {
        return (
          <Checkbox
            key={key.Id}
            value={key.Id}
            label={key.Attribute}
            boxchecked={this.toggleCheckbox}
          />
        )
      })

    const filterSections = [
      features,
      configurations,
      fastenings,
      shapes,
      cylinder_types,
      units,
      materials,
      dates,
      zones,
    ]

    let filterList = filterSections.map((filter, index) => {
      let sectionNames = [
        'Features',
        'Configuration',
        'Fastening',
        'Shape',
        'Cylinder Type',
        'Unit of Measure',
        'Material',
        'Apx Install Date',
        'Install Location',
      ]
      let currentSection = sectionNames[index]

      return (
        <FilterSection
          key={sectionNames[index]}
          filters={filter}
          head={currentSection}
        />
      )
    })

    const mobileFilters = this.state.attributeIds.map(key => {
      return (
        <div key={key.Id + 'div'} className={styles.mobiledCheck}>
          <Checkbox
            key={key.Id}
            value={key.Id}
            label={key.Attribute}
            boxchecked={this.toggleCheckbox}
          />
        </div>
      )
    })

    // End checkbox filters

    // Manufacturer select options

    let manuSelect = this.state.manufacturers.map(manufacturer => {
      return { label: manufacturer.ManufacturerName, value: manufacturer.Id }
    })

    let iechiSelect = manuSelect.map(option => {
      return (
        <option key={option.value} value={option.label}>
          {option.label}
        </option>
      )
    })

    // end select options

    let selectClass
    selectClass = cx({
      selected: true,
    })

    return (
      <div className={styles.pageWrapper}>
        <Helmet>
          <title>
            Elevator Cylinder Head Identification | Texacone | It's Easy With
            Our App
          </title>
          <meta
            name="description"
            content="Use our interactive elevator cylinder head ID to find the right cylinder head for you. Have a smart phone? Download our app to look on the go!"
          />
        </Helmet>
        <ScrollToTop />
        <Topper />
        {/* <div id="logout" onClick={this.logout}>Logout</div> */}
        <p className={styles.appSuggestion}>
          For a better mobile experience, download the Texacone App!
        </p>
        <p className={styles.appLinks}>
          <a
            href="https://itunes.apple.com/us/app/texacone-on-site/id1131678695?mt=8"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apple App Store
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.cratebind.texacone&hl=en_US"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Play Store
          </a>
        </p>
        {!this.state.currentHead ? (
          <div className={styles.titleDiv}>
            <p className={styles.filterTitle}>Filter</p>
            <p className={styles.headerTitle}>{this.state.titleHead}</p>
            <p className={styles.numberButton}>Set Number</p>
            <input
              type="text"
              placeholder="..."
              value={this.state.partNumber}
              onChange={this.handlePartNumber}
            />
          </div>
        ) : null}
        {!this.state.currentHead &&
        localStorage.getItem('isLogged') === 'true' ? (
          <div className={styles.mobileFilters}>{mobileFilters}</div>
        ) : null}
        {!this.state.currentHead ? (
          <React.Fragment>
            {localStorage.getItem('isLogged') === 'true' ? (
              <div className={styles.selectionWrapper}>
                <div className={styles.clearFilter} onClick={this.clearFilter}>
                  <p>Clear Filters</p>
                </div>
                <div className={styles.typeSelect}>
                  {['Attributes', 'Manufacturers'].map((el, index) => (
                    <div
                      key={el + index}
                      className={
                        index === this.state.activeId ? selectClass : ''
                      }
                      onClick={e => this.setActiveElement(e, index)}
                    >
                      <h2>{el}</h2>
                    </div>
                  ))}
                </div>
                {this.state.activeId === 0 && filterList}
                {this.state.activeId === 1 && (
                  <div className={styles.manuList}>
                    <select
                      className={styles.chooseSelect}
                      onChange={this.chooseSelect}
                    >
                      {iechiSelect}
                    </select>
                  </div>
                )}
                {/* {this.state.activeId === 2 &&
              <div className={styles.partDiv}>
                <input type="text" placeholder="Enter part number..." value={this.state.partNumber} onChange={this.handlePartNumber} />
              </div>
              } */}
              </div>
            ) : (
              <h1 className={styles.loggedOut}>
                You are currently logged out. <br />{' '}
                <Link to="/login">Login</Link> to allow filtering of displayed
                heads.
              </h1>
            )}
            <div className={styles.headWrapper}>
              {this.state.iechierror ? (
                <h1>There has been an error loading head data</h1>
              ) : null}
              {this.state.isLoading &&
              this.state.msElapsed > this.showSpinnerIfReturnGreaterThanMs ? (
                <Spinner />
              ) : (
                heads
              )}
            </div>
          </React.Fragment>
        ) : (
          <HeadData
            head={this.state.currentHead}
            closeHead={this.closeHead}
            index={this.state.currentHeadIndex}
            clear={this.clearFilter}
          />
        )}
      </div>
    )
  }
}

export default IECHI
