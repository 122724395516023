import React from 'react';
import styles from './partTable.module.scss';

const partTable = (props) => {
  let table = props.table;
  let notes = props.table.notes.map(note =>
    <p key={note}>{note}</p>
  );



  let rows = table.packings.map((row,index) => {
    return (
      <tr key={row.machine_number + index}>
        <td className={styles.col}>{row.machine_number}</td>
        <td className={styles.col}>{row.packing_dimensions}</td>
        <td className={styles.col}>{row.reference_number}</td>
        <td className={styles.col}>{row.part_number}</td>
      </tr>
    );
  }

  )
  return (
    <div className={styles.tableWrapper}>
      <p id={table.manufacturer_name} className={styles.manuName}>{table.manufacturer_name}</p>
      <table>
        <thead>

        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
      <div className={styles.notesWrapper}>
        {notes}
      </div>
      <p className={styles.backTop} onClick={props.scrollTop}>Back to top</p>
    </div>
  );
};

export default partTable;