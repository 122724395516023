import React, { Component } from 'react'
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu'
// import { Link } from 'react-router-dom';
import Modal from '../modals/menu/menu'
import { HashLink as Link } from 'react-router-hash-link'
import styles from './Header.module.scss'
import logo from '../../assets/images/texacone_logo_white.png'

class Header extends Component {
  state = {
    modalOpen: false,
  }

  burgerClick = () => {
    if (this.state.modalOpen) {
      this.setState({ modalOpen: false })
    } else {
      this.setState({ modalOpen: true })
    }
  }

  logoClick = () => {
    if (this.state.modalOpen) {
      this.setState({ modalOpen: false })
    }
  }

  render() {
    return (
      <div className={styles.pageHeader}>
        <Link to="/" onClick={this.logoClick}>
          <img src={logo} className={styles.logo} alt="Texacone Logo" />
        </Link>
        <div className={styles.contactBlock}>
          <Link to="/products#order-form" className={styles.orderLink}>
            ORDER REQUEST
          </Link>
          <Link to="/contact" className={styles.contactLink}>
            CONTACT US
          </Link>
          <a className={styles.phoneLink} href="tel:9722884404">
            (972) 288-4404
          </a>
          <HamburgerMenu
            open={this.burgerClick}
            opened={this.state.modalOpen}
          />
        </div>
        <Modal show={this.state.modalOpen} closed={this.burgerClick} />
      </div>
    )
  }
}

export default Header
