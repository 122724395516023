import React, { Component } from 'react'
import { Query } from 'react-apollo'
import Spinner from '../../components/spinner/spinner'
import gql from 'graphql-tag'
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop'
import Topper from './sections/topper/topper'
import Count from './sections/count/count'
import ProductChoice from './sections/product_choice/product_choice'
import Videos from './sections/videos_section/videos_section'
import Form from './sections/orderform/form'
import { Helmet } from 'react-helmet'
import styles from './Products.module.scss'

export default class Products extends Component {
  componentDidMount() {}

  render() {
    const SECTIONS_QUERY = gql`
      query sectionNames {
        pages(where: { page_name: "products" }) {
          sections {
            components {
              component_name
            }
          }
        }
      }
    `

    return (
      <Query query={SECTIONS_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <Spinner />
          if (error) return `Error! ${error.message}`

          // WIP to call components based on returned GQL data

          // const section_names = {}

          // data.sections.forEach(section => {
          //   let section_name = section.section_name;
          //   let upper_name = section_name.charAt(0).toUpperCase() + section_name.slice(1);
          //   let section_value = {upper_name}
          //   section_names[section_name] = section_value;
          // })

          // const sections = data.sections.map(section => {
          //   const Section = section_names[section.section_name];
          //   return(
          //     <Section />
          //   )
          // });

          return (
            <div className={styles.mainWindow}>
              <Helmet>
                <title>Products | Texacone Company</title>
                <meta
                  name="description"
                  content="If you need the best in elevator packing materials and accessories, you’re in the right place. For over 80 years, Texacone has led the industry in manufacturing the highest-quality sealing parts for both hydraulic and traction machine elevators."
                />
              </Helmet>
              <ScrollToTop />
              <div className={styles.sectionWrapper}>
                <Topper />
                <Count />
                <ProductChoice />
                <Videos />
                <Form />
              </div>
            </div>
          )
        }}
      </Query>
    )
  }
}
