import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import styles from './menu.module.scss';

const status = window.localStorage.getItem('isLogged');

const menu_modal = (props) => {

  let logout = () => {
    window.localStorage.removeItem('isLogged');
    props.closed();
  }

  const [logged, setLogged] = useState(status);

  useEffect((status) => {
    status = window.localStorage.getItem('isLogged');
    setLogged(status);
  })

  if(!props.show) {
    return null;
  } else {
    return (
      <div className={styles.screenOverlay}>
        <div className={styles.modalWrapper}>
          <div className={styles.menuContainer}>
            <Link to="/" onClick={props.closed}>HOME</Link>
            <Link to="/texhead" onClick={props.closed}>CYLINDER HEAD ID</Link>
            <Link to="/products" onClick={props.closed}>PRODUCTS</Link>
            <Link to="/videos_and_downloads" onClick={props.closed}>VIDEOS+DOWNLOADS</Link>
            <Link to="/about" onClick={props.closed}>ABOUT</Link>
            { !logged ? <Link to="/login" onClick={props.closed}>LOGIN+REGISTER</Link> : <p onClick={logout}>LOGOUT</p> }

            <Link to="/contact" onClick={props.closed}>CONTACT</Link>
          </div>
        </div>
      </div>
    );
  }
};

export default menu_modal;