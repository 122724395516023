import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './locations.module.scss';

let cx = classNames.bind(styles);

const location = (props) => {


  const [isOpen, setOpen] = useState(false);
  const [locpicString, setLocPicString] = useState();

  useEffect(() => {
    let newArr = [];
    if (props.data.Zone1) {
      newArr.push(1)
    }
    if (props.data.Zone2) {
      newArr.push(2)
    }
    if (props.data.Zone3) {
      newArr.push(3)
    }
    if (props.data.Zone4) {
      newArr.push(4)
    }
    if (props.data.Zone5) {
      newArr.push(5)
    }
    if (props.data.Zone6) {
      newArr.push(6)
    }
    if (props.data.Zone7) {
      newArr.push(7)
    }

    setLocPicString(newArr.join(""));
  }, [props.data])




  let arrowClass;
  if (isOpen) {
    arrowClass = cx({
      arrowOpen: true,
    });
  } else {
    arrowClass = cx({
      arrowClose: true,
    });
  }

  let locationHandler = (e) => {
    setOpen(!isOpen);
  }


  return (
    <React.Fragment>
      <div className={styles.collapseData}>
        <h3 onClick={locationHandler} className={arrowClass}>Installation Locations</h3>
        { isOpen ?
          <div className={styles.dataDiv}>
            <img className={styles.locMap} src={`https://texacone.com/IECHI/Content/images/maps/map${locpicString}.jpg`} alt={locpicString}></img>
        </div>
          : null
        }
      </div>
    </React.Fragment>
  );
};

export default location;