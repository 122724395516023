import React from 'react';
import {graphql, Query} from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../../components/spinner/spinner';
import ScrollToTop from '../../../components/ScrollToTop/ScrollToTop';
import { HashLink as Link } from 'react-router-hash-link';
import PartTable from './partTable/partTable';
import wormgear from '../../../assets/images/WGS-1R.jpg';
import wormshaft from '../../../assets/images/WSP-10PA.jpg';
import spiderseal from '../../../assets/images/Flange-Wiper.jpg';
import { Helmet } from 'react-helmet';
import styles from './traction_parts.module.scss';


const COMPONENT_QUERY = gql`query getList {
  components (where: {component_name: "traction_machine_part_list"}) {
    component_name
    list
  }
}`;


const tractionmachine = (props) => (

  <Query query={COMPONENT_QUERY}>
    {({ loading, error, data }) => {
      if (loading) return <Spinner />
      if (error) return `Error! ${error.message}`;
      let scrollTop = () => {
        window.scrollTo(0, 0);
      }

      let list = data.components[0].list.manufacturers;
      let selection_list = data.components[0].list.manufacturers.map((name, index) => {
        return (
          <Link key={name + index} to={{
            pathname:"/tractionmachinepackings",
            hash: name.manufacturer_name,
            }
          }>
          {name.manufacturer_name}
          </Link>
        );
      });
      let tables = list.map((table, index) => {
        return (
          <PartTable
            key={table.manufacturer_name + index}
            table={table}
            scrollTop={scrollTop}
          />
            );
        });





      return (
        <div className={styles.tmWrapper}>
        <Helmet>
            <title>Traction Machine Packings | The Best In The Elevator Industry</title>
            <meta name="description" content="Elevator traction machine packing product descriptions including Hi-Temp Worm Gear Seal, Worm Shaft Packing, Sheave-Spider seal and Complete Westinghouse kits. Learn more!" />
          </Helmet>
        <ScrollToTop />
          <div className={styles.topWrapper}>
            <div className={styles.selectionList}>
              <h1>Traction Machine Packings by manufacturer:</h1>
              <p>Click below to jump to a specific manufacturer:</p>
              {selection_list}
            </div>
            <div className={styles.packingDescriptions}>
              <h1>Traction Machine Packing Descriptions</h1>
              <div className={styles.descriptionImages}>
                <div className={styles.item}>
                  <img src={wormgear} alt="Worm Gear Seal" />
                  <div className={styles.col}>
                    <h3>Worm Gear Seal (1R)</h3>
                    <ul>
                      <li>New "HT" elastomer is a very high temperature, abrasion resistant compound.</li>
                      <li>Outlasts standard oil seal compounds in demanding environments.</li>
                      <li>Outer fabric component with Fabrilok assures snug press fit into housing to eliminate spinning or "walkout".</li>
                      <li>Lip design provides light sealing pressures with excellent flexibility to suppress heat build up with eccentric loads.</li>
                      <li>Incorporates unique dove-tailed split joint for perfect lip alignment.</li>
                    </ul>
                  </div>
                </div>
                <div className={styles.item}>
                  <img src={wormshaft} alt="Worm Shaft Packing" />
                  <div className={styles.col}>
                    <h3>Worm Shaft Packing (10PA)</h3>
                    <ul>
                      <li>For use on machines incorporating “Packing Boxes”, MUST be compressed.</li>
                      <li>Nested Vee design provides excellent split sealing without binding and allows for occasional adjustments to extend packing life.</li>
                      <li>Excellent for worn shafts.</li>
                      <li>Elastomer blend reduces heat build up to ensure long packing life.</li>
                    </ul>
                  </div>
                </div>
                <div className={styles.item}>
                  <img src={spiderseal} alt="Sheave / Spider Seal" />
                  <div className={styles.col}>
                    <h3>Sheave / Spider Seal</h3>
                    <ul>
                      <li>Prevents oil mist from coating machine and surrounding area.</li>
                      <li>Designed specifically for each manufacturer’s machine.</li>
                      <li>Split for easy installation.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.tablesWrapper}>
            {tables}
          </div>
        </div>
      );
    }}
  </Query>
);


export default graphql(COMPONENT_QUERY)(tractionmachine);
