import React, { Component } from 'react';
import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../../components/spinner/spinner';
import ScrollToTop from '../../../components/ScrollToTop/ScrollToTop';
import ToolTopper from './sections/hyd_topper/hyd_topper';
import ToolSection from './sections/hyd_section/hyd_section';
import { Helmet } from 'react-helmet';
import styles from './Hydraulic.module.scss';

export default class Hydraulic extends Component {

  componentDidMount() {


  }

  render() {

    const SECTIONS_QUERY = gql`query sectionNames {
      pages (where: { page_name: "hydraulics" }) {
        sections {
          components {
            component_name
          }
        }
      }
    }`;

    return (

    <Query query={SECTIONS_QUERY}>
      {({ loading, error, data }) => {
        if (loading) return <Spinner />
        if (error) return `Error! ${error.message}`;

        // WIP to call components based on returned GQL data

        // const section_names = {}

        // data.sections.forEach(section => {
        //   let section_name = section.section_name;
        //   let upper_name = section_name.charAt(0).toUpperCase() + section_name.slice(1);
        //   let section_value = {upper_name}
        //   section_names[section_name] = section_value;
        // })



        // const sections = data.sections.map(section => {
        //   const Section = section_names[section.section_name];
        //   return(
        //     <Section />
        //   )
        // });


        return (
        <div className={styles.mainWindow}>
          <Helmet>
            <title>General Hydraulic and Pneumatic Products | The Best In The Elevator Industry</title>
            <meta name="description" content="Texacone specializes in molded seals up to 65-inch diameter in solid or split from a wide variety of fabric-reinforced elastomers. High temperature materials also available. Learn more!" />
          </Helmet>
          <ScrollToTop />
          <div className={styles.sectionWrapper}>
            <ToolTopper />
            <ToolSection />
          </div>
        </div>
        );
      }}
    </Query>
    )
  };
}
