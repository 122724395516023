import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import styles from './Policy.module.scss'

class Policy extends Component {
  render() {
    return (
      <div className={styles.contactWrapper}>
        <Helmet>
          <title>Privacy Policy | Texacone</title>
          <meta
            name="description"
            content="Policy for Texacone site data collection"
          />
        </Helmet>
        <div className={styles.internalText}>
          <p>
            The Site uses Google Analytics, a web analysis service provided by
            Google Inc., to collect information about your use of the Site,
            which information your web browser automatically sends to Google.
            This information includes the URLs of the web sites that you visit
            and your IP address. Google may also set cookies or read preexisting
            cookies. This information is stored on Google’s servers. Google uses
            this information to provide us with reports about Site traffic and
            your visits to the Site (for example, the domain from which you
            access the Internet, the web address of the web site from which you
            linked to the Site, and the date and time of your visit to the web
            sites that you view and click through). We will use this data to
            improve the Site’s structure and content. Google may use this data
            in accordance with its Privacy Policy, which is located at
            <a href="https://www.google.com/policies/privacy">
              www.google.com/policies/privacy
            </a>
            . For more information on how Google uses your data, visit
            <a href="https://www.google.com/policies/privacy/partners">
              www.google.com/policies/privacy/partners
            </a>
            . .You may opt out of our use of Google Analytics by visiting the
            Google Analytics opt-out web site at{' '}
            <a href="https://tools.google.com/dlpage/gaoptout">
              tools.google.com/dlpage/gaoptout
            </a>{' '}
            and installing the Google Analytics Opt-Out Browser Add-On. Please
            remember that you will need to opt out for each browser you use.
            Opting out and otherwise refusing cookies will also limit the
            collection of analytics data from your use of the Site.
          </p>
          <p>
            We have enabled the following Google Analytics Advertising Features:
            Remarketing with Analytics, Demographics and Interest Reporting, and
            Segments. Remarketing with Analytics uses the Google Analytics
            cookie to serve advertisements to you across the Internet based on
            your visit to the Site. Demographics and Interest Reporting uses a
            third-party cookie to collect information about our Site traffic by
            tracking users across web sites and across time, which generates a
            report for us to better understand our Site users. Segments allows
            us to isolate and analyze subsets of Site users by sorting our
            Google Analytics data. If you do not want Google to collect or use
            this information, visit Google’s Safety Center at
            <a href="https://www.google.com/safetycenter/tools">
              www.google.com/safetycenter/tools
            </a>
            . Opting out will not affect your use of the Site. You can also
            visit
            <a href="https://www.networkadvertising.org/choices">
              www.networkadvertising.org/choices
            </a>
            to change your preferences with respect to certain online ads and to
            obtain further information about third-party ad networks and
            behavioral advertising. Please remember that changing your settings
            with certain browsers and ad networks will not carry your privacy
            choices across browsers and other ad networks.
          </p>
          <p>
            We also use Google AdWords, an online advertising service provided
            by Google, to serve ads on our behalf across the Internet and
            sometimes on the Site. Google uses your Internet searches and
            cookies and similar identifiers (e.g., pixel tags) to collect
            anonymous information about your visits to the Site and your
            interaction with our products and services to generate targeted
            advertisements to you on other web sites that you visit across the
            Internet. To opt out of remarketing advertising provided through
            Google or to customize your ad preferences, visit Google’s Ad
            Settings page at
            <a href="https://www.google.com/settings/ads">
              www.google.com/settings/ads
            </a>
            . Opting out will not affect your use of the Site. If you do not
            want Google to collect or use this information, visit Google’s
            Safety Center at
            <a href="https://www.google.com/safetycenter/tools">
              www.google.com/safetycenter/tools
            </a>
            . Opting out will not affect your use of the Site. To obtain more
            information about third-party ad networks and online behavioral
            advertising and to change your preferences with respect to certain
            online ads, please visit the National Advertising Initiative
            Consumer Opt-Out web site at
            <a href="https://www.networkadvertising.org">
              www.networkadvertising.org
            </a>{' '}
            choices or the Digital Advertising Alliance Self-Regulatory Program
            web site at{' '}
            <a href="https://www.aboutads.info">www.aboutads.info</a>. Please
            remember that opting out of online ads with one ad network and web
            browser does not necessarily carry over to any other ad network or
            web browser. As a result, depending on the opt-outs you request, you
            may still see our ads from time to time.
          </p>
          <p>
            Thank you for taking the time to learn more about The Texacone
            Company. After you've read about us, please contact us online or
            call us at (972) 288-4404 so we can discuss your needs.
          </p>
        </div>
      </div>
    )
  }
}

export default Policy
