import React from 'react';
import {graphql, Query} from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../../../components/spinner/spinner';
import NewsPost from './newsposts/newspost';
import styles from './news.module.scss';


const COMPONENT_QUERY = gql`query sectionComponents {
  sections (where: { section_name: "news", page_name: "about_us" }) {
    header1
    description1
    posts (sort: "dateposted:desc") {
      _id
      title
      description
      dateposted
      media1 {
        url
        name
      }
    }

  }
}`;


const newsSection = () => (
  <Query query={COMPONENT_QUERY}>
    {({ loading, error, data }) => {
      if (loading) return <Spinner />
      if (error) return `Error! ${error.message}`;
      let posts = data.sections[0].posts.map((post,index) => {
        return (
          <NewsPost
            id={post._id}
            key={post._id}
            post_data={post}
            index={index}
            img={post.media1.url}
            img_alt={post.media1.name}
            />
            );
        });


      return (
        <div className={styles.sectionCard}>
          <div className={styles.newsContainer}>
            {posts}
          </div>
        </div>
      );
    }}
  </Query>
);


export default graphql(COMPONENT_QUERY)(newsSection);
