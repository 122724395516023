import React, { Component } from 'react';
import Topper from './sections/topper/topper';
import Downloads from './downloads/downloads';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import styles from './Videos.module.scss';

export default class Videos extends Component {

  componentDidMount() {


  }


  render() {

    return (

      <div className={styles.mainWindow}>
        <Helmet>
          <title>Instructional and How-To Videos | Texacone</title>
          <meta name="description" content="Learn helpful tips and tricks in our series of how-to-videos. You'll gain a wealth of information on cylinder head installation, seal repairs, and more." />
        </Helmet>
        <div className={styles.sectionWrapper}>
          <ScrollToTop />
          <Topper />
          <Downloads />
        </div>
      </div>
    )
  };
}