import React from 'react';
import {graphql, Query} from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../../../components/spinner/spinner';
import styles from './seals.module.scss';



const COMPONENT_QUERY = gql`query sectionComponents {
  sections (where: { section_name: "seals", page_name: "home" }) {
    header1
    description1
    description2
    media1 {
      url
      name
    }
  }
}`;


const moldingSection = () => (
  <Query query={COMPONENT_QUERY}>
    {({ loading, error, data }) => {
      if (loading) return <Spinner />
      if (error) return `Error! ${error.message}`;
      let imgurl = "https://anchor-api.texacone.com" + data.sections[0].media1.url;


      let cardStyles = {
        backgroundImage: `linear-gradient(rgba(0,0,0, 0.15), rgba(0,0,0, 0.35)), url(${imgurl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      };

      let Description = () => {
        return(
          <p>{data.sections[0].description1}</p>
        );
      }

      let Header = () => {
        return(
          <h1>{data.sections[0].header1}</h1>
        )
      }


      return (
        <div className={styles.sectionCard}>
          <div className={styles.leftField}>
            {(data.sections[0].header1) ? <Header /> : null}
            {(data.sections[0].description1) ? <Description /> : null}
          </div>
          <div className={styles.rightField}>
            <div className={styles.sealsContainer} style={cardStyles}>
              <p>{data.sections[0].description2}</p>
            </div>
          </div>
        </div>
      );
    }}
  </Query>
);


export default graphql(COMPONENT_QUERY)(moldingSection);
