import React from 'react';
import {graphql, Query} from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../../../components/spinner/spinner';
import classNames from 'classnames/bind';
import SelectionButton from './selection_buttons/selectionButton';
import styles from './selection.module.scss';
let cx = classNames.bind(styles);



const COMPONENT_QUERY = gql`query sectionComponents {
  sections (where: { section_name: "selection", page_name: "home" }) {
    header1
    description1
    components {
      component_name
      _id
      textfield1
      textfield2
      header1
      header2
      media1 {
        url
        name
      }
      media2 {
        url
        name
      }
      media3 {
        url
        name
      }
    }
  }
}`;


const selectionsection = () => (
  <Query query={COMPONENT_QUERY}>
    {({ loading, error, data }) => {
      if (loading) return <Spinner />
      if (error) return `Error! ${error.message}`;
      let components = data.sections[0].components;
      let buttons = components.map(button => {
        return (
          <SelectionButton
            id={button._id}
            key={button._id}
            components={button}
            />
            );
        });
        let leftClasses = cx({
          leftField: true,
          firstLeft: true
        });

      return (
        <div className={styles.sectionCard}>
          <div className={leftClasses}>
            <h1>{data.sections[0].header1}</h1>
            <p>{data.sections[0].description1}</p>
          </div>
          <div className={styles.rightField}>
            <div className={styles.selectionContainer}>
              <div className={styles.buttonContainer}>
                { buttons }
              </div>
            </div>
          </div>
        </div>
      );
    }}
  </Query>
);


export default graphql(COMPONENT_QUERY)(selectionsection);
