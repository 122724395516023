import React, { Component } from 'react';
import classNames from 'classnames/bind';
import styles from './HamburgerMenu.module.scss';
let cx = classNames.bind(styles);

class HamburgerMenu extends Component {

  render() {
    let burgerClass;
    if (this.props.opened) {
      burgerClass = cx({
        burger_menu: true,
        open: true
      });
    } else {
      burgerClass = cx({
        burger_menu: true,
      });
    }

    return (
        <div className={burgerClass} onClick={this.props.open}>
          <div className={styles.bar1} key="b1" />
          <div className={styles.bar2} key="b2" />
          <div className={styles.bar3} key="b3" />
        </div>
    );
  }
}

export default HamburgerMenu;