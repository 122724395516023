import React from 'react';
import { Link } from 'react-router-dom';
import styles from './product_option.module.scss';

const product_option = (props) => {

  let imgurl = "https://anchor-api.texacone.com" + props.components.media1.url
    let inlinestyles = {
      backgroundImage: `linear-gradient(rgba(0,0,0, 0.23), rgba(0,0,0, 0.23)), url(${imgurl})`,
      backgroundSize: 'auto, cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    };

  return (
    <Link to={props.components.textfield1} style={{ textDecoration: 'none'}}>
      <div className={styles.cardWrapper} style={inlinestyles}>
        <p>{props.components.header1}</p>
      </div>
    </Link>
  );
};

export default product_option;