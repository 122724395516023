import React from 'react';
import {graphql, Query} from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../../components/spinner/spinner';
import styles from './downloads.module.scss';


const COMPONENT_QUERY = gql`query sectionComponents {
  downloads {
    _id
    download_name
    media1 {
      url
      name
    }
  }
}`;


const downloadsection = () => (
  <Query query={COMPONENT_QUERY}>
    {({ loading, error, data }) => {
      if (loading) return <Spinner />
      if (error) return `Error! ${error.message}`;
      let downloads = data.downloads.map(download => {
        let dl_url = "https://anchor-api.texacone.com" + download.media1.url;
        return (
          <li  key={download._id}>
            <a href={dl_url} target="_blank" rel='noopener noreferrer'>{download.download_name}</a>
          </li>
            );
        });

      return (
        <div className={styles.downloadWrapper}>
          <h1>DOWNLOADS</h1>
          <ul>
          {downloads}
          </ul>
        </div>
      );
    }}
  </Query>
);


export default graphql(COMPONENT_QUERY)(downloadsection);
