import React from 'react';
import {graphql, Query} from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../../../components/spinner/spinner';
import Scroller from './scroller/scroller';
import styles from './videos_section.module.scss';


const COMPONENT_QUERY = gql`query sectionComponents {
  sections (where: { section_name: "videos", page_name: "products" }) {
    header1
    description1
    media1 {
      url
      name
    }
    components {
      component_name
      _id
      textfield1
      textfield2
      header1
      header2
      media1 {
        url
        name
      }
      media2 {
        url
        name
      }
      media3 {
        url
        name
      }
    }
  }
}`;


const videossection = () => (
  <Query query={COMPONENT_QUERY}>
    {({ loading, error, data }) => {
      if (loading) return <Spinner />
      if (error) return `Error! ${error.message}`;
      let iconurl = "https://anchor-api.texacone.com" + data.sections[0].media1.url

      return (
        <div className={styles.videosWrapper}>
            <div className={styles.videosBanner}>
              <img src={iconurl} alt={data.sections[0].media1.name} />
              <h1>{data.sections[0].header1}</h1>
            </div>
        <Scroller />
        </div>
      );
    }}
  </Query>
);


export default graphql(COMPONENT_QUERY)(videossection);
