import React from 'react';
import {graphql, Query} from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../../../components/spinner/spinner';
import MarkdownViewer from '../../../../components/MarkdownViewer';
import styles from './molding.module.scss';



const COMPONENT_QUERY = gql`query sectionComponents {
  sections (where: { section_name: "molding", page_name: "home" }) {
    header1
    description1
    components {
      component_name
      _id
      textfield1
      textfield2
      header1
      header2
      media1 {
        url
        name
      }
      media2 {
        url
        name
      }
      media3 {
        url
        name
      }
    }
  }
}`;


const moldingSection = () => (
  <Query query={COMPONENT_QUERY}>
    {({ loading, error, data }) => {
      if (loading) return <Spinner />
      if (error) return `Error! ${error.message}`;
      let components = data.sections[0].components;
      let imgurl = "https://anchor-api.texacone.com" + components[0].media1.url;


      let cardStyles = {
        backgroundImage: `url(${imgurl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      };

      let Description = () => {
        return(
          <p>{data.sections[0].description1}</p>
        );
      }


      return (
        <div className={styles.sectionCard}>
          <div className={styles.leftField}>
            <h1>{data.sections[0].header1}</h1>
            {(data.sections[0].description1) ? <Description /> : null}
          </div>
          <div className={styles.rightField}>
            <div className={styles.moldingContainer} style={cardStyles}>
            <MarkdownViewer md={components[0].textfield1}/>
            </div>
          </div>
        </div>
      );
    }}
  </Query>
);


export default graphql(COMPONENT_QUERY)(moldingSection);
