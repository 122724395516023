
import React from 'react';

const MarkdownViewer = (props) => {
  var Remarkable = require('remarkable');
  var md = new Remarkable({
    html: true,
  });
  var markdown = md.render(props.md);
  return <div dangerouslySetInnerHTML={{__html:markdown}} />;
}

export default MarkdownViewer;