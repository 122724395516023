import React, { Component } from 'react';
import {Query} from 'react-apollo';
import Spinner from '../../components/spinner/spinner';
import gql from 'graphql-tag';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import { Parallax } from 'react-parallax';
import { Helmet } from 'react-helmet';
import Selection from './sections/selection/selection';
import Phone from './sections/phone/phone';
import Seals from './sections/seals/seals';
import Molding from './sections/molding/molding';
import bannerClose from '../../assets/images/xwhite.png'
import styles from './Home.module.scss';

export default class Home extends Component {

  state = {
    banner: true,
  }

  bannerHandler = () => {
    let bannerState = this.state.banner;
    this.setState({banner: !bannerState})
  }

  componentDidMount() {


  }

  render() {

    const SECTIONS_QUERY = gql`query sectionNames {
      sections {
        components {
          component_name
        }
      }
    }`;

    return (

    <Query query={SECTIONS_QUERY}>
      {({ loading, error, data }) => {
        if (loading) return <Spinner />
        if (error) return `Error! ${error.message}`;

        // WIP to call components based on returned GQL data

        // const section_names = {}

        // data.sections.forEach(section => {
        //   let section_name = section.section_name;
        //   let upper_name = section_name.charAt(0).toUpperCase() + section_name.slice(1);
        //   let section_value = {upper_name}
        //   section_names[section_name] = section_value;
        // })



        // const sections = data.sections.map(section => {
        //   const Section = section_names[section.section_name];
        //   return(
        //     <Section />
        //   )
        // });


        return (
        <div className={styles.mainWindow}>
          <Helmet>
            <title>Texacone | Experience New Levels of Elevator Seal Performance</title>
            <meta name="description" content="From elevator seals and packing to tools and accessories, trust the experts at Texacone to help keep your elevators up and running." />
            <meta name="google-site-verification" content="W7j2Y9QotpCQXr8v5ZJQDVxn8VUousDPuzxHV2Tw_2g" />
          </Helmet>
          <ScrollToTop />
          <Parallax
            blur={0}
            bgImage={require('../../assets/images/clear_elevator.jpeg')}
            bgImageAlt="elevator"
            strength={1750}
          >
            <div className={styles.sectionWrapper}>
              { this.state.banner ?
              <div className={styles.tempBanner}>
                <h3>SAME DAY SHIPPING ON ALL IN STOCK PRODUCTS IF ORDERED BEFORE 3PM CST.</h3>
                <img src={bannerClose} alt="Modal Close" onClick={this.bannerHandler} />
              </div> :
              null
            }
              <Selection />
              <Seals />
              <Phone />
              <Molding />
            </div>
          </Parallax>
        </div>
        );
      }}
    </Query>
    )
  };
}
