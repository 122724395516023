import React, { useState } from 'react';
import ScrollToTop from '../../ScrollToTop/ScrollToTop';
import closeButton from '../../../assets/images/close-button.png';
import axios from 'axios';
import classNames from 'classnames/bind';
import { Helmet } from 'react-helmet';
import styles from './Login.module.scss';

const Login = (props) => {

let cx = classNames.bind(styles);


const [email, setEmail] = useState('');
const [password, setPassword] = useState('');
const [loginError, setLoginError] = useState(false);
const [errorMessage, seterrorMessage] = useState('');
const [activeId, setActiveId] = useState(0);
const [login, setLogin] = useLocalStorage('isLogged', false);

let selectLog;
selectLog = cx({
  selected: true,
});


  // Hook
function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

  let validateForm = () => {
    return email.length > 0 && password.length > 0;
  }

  let emailChange = event => {
    setEmail(event.target.value);
  }

  let passwordChange = event => {
    setPassword(event.target.value);
  }


  let handleSubmit = event => {
    event.preventDefault();
    axios({
      headers: {},
      url: 'https://www.texacone.com/IECHI/API/VerifyLogin',
      method: 'post',
      params: {
        Username: email,
        Password: password,
      }
    })
    .then(response => {
      setLogin(response.data.Success);
      if (response.data.Success === true) {
        setLoginError(false);
        props.history.goBack();
      } else {
        setLoginError(true);
        seterrorMessage(response.data.Message)
      }
    })
    .catch(error => {
      console.log("error was" + error)
    })
  }

  let closeHandle = () => {
    props.history.goBack();
  }

  return (
    <div className={styles.loginWrapper}>
      <Helmet>
        <title>Login | Texacone</title>
        <meta name="description" content="Login to search the Elevator Parts Catalog using the Interactive Elevator Cylinder Head Identification" />
      </Helmet>
      <ScrollToTop />
      <div className={styles.login}>
        <div className={styles.typeSelect}>
          {
            ['Login','Register'].map((el, index) =>
              <div key={el + index} className={index === activeId ? selectLog : ""} onClick={(e) => setActiveId(index)}>
                <h2>
                  {el}
                </h2>
              </div>
            )
          }
          <img className={styles.loginCloseButton} src={closeButton} alt="close window" onClick={closeHandle} />
        </div>
        { activeId === 0 ?
        <form onSubmit={handleSubmit}>
        <input
          id="email"
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={emailChange}
        />
        <input
          id="password"
          type="password"
          placeholder="Password"
          onChange={passwordChange}
          value={password}
        />
        <a href="https://www.texacone.com/IECHI/Account/ForgotPassword" target="_blank" rel="noopener noreferrer">Forgot password?</a>
      <button type="submit" disabled={!validateForm()}>

        Login
      </button>
      { loginError ? <h3>{errorMessage}</h3> : null }
    </form> :
      <div className={styles.registerText}>
        <p>Need an account?</p>
        <p>  Click <a href="https://www.texacone.com/IECHI/Account/Register" target="_blank" rel="noopener noreferrer">HERE</a> to register.</p>
        <p>  You will be directed to register in another window. Return to this site and login to continue after registration.</p>
      </div>
        }

      </div>
    </div>
  );
};


export default Login;
