import React from 'react';
import styles from './video_card.module.scss';

const video_card = (props) => {

  let imgurl = "https://anchor-api.texacone.com" + props.components.media1.url
    let inlinestyles = {
      backgroundImage: `linear-gradient(rgba(0,0,0, 0.35), rgba(0,0,0, 0.35)), url(${imgurl})`,
      backgroundSize: 'auto, cover',
      backgroundRepeat: 'no-repeat'
    };


  return (
    <a href={props.components.header2} target="_blank" rel="noopener noreferrer">
      <div className={styles.videoCard} style={inlinestyles}>
        <p>{props.components.header1}</p>
      </div>
    </a>
  );
};

export default video_card;