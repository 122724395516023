import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ScrollToTop from '../../../components/ScrollToTop/ScrollToTop';
import CylNotes from './sections/cylnotes/cylnotes';
import PackInfo from './sections/packinfo/packinfo';
import PackNotes from './sections/packnotes/packnotes';
import HeadNotes from './sections/headnotes/headnotes';
import PackCont from './sections/packcont/packcont';
import PackAlt from './sections/packalt/packalt';
import Locations from './sections/locations/locations';
import TroubleNotes from './sections/other/other';
import styles from './headdata.module.scss';

const headdata = props => {
  let head = props.head;
  let index = props.index;

  let logStatus = window.localStorage.getItem('isLogged');

  const [galImageIndex, setGalImageIndex] = useState(index);
  const [galImage, setGalImage] = useState(
    head.Drawings[galImageIndex].FileName
  );
  const [imageAlt, setImageAlt] = useState(
    head.Drawings[galImageIndex].FileName
  );
  const [imageCap, setImageCap] = useState(
    head.Drawings[galImageIndex].Caption
  );
  const [isLogged, setLogged] = useState(logStatus);

  let changeImage = index => {
    setGalImageIndex(index);
    setGalImage(head.Drawings[index].FileName);
    setImageAlt(head.Drawings[index].FileName);
    setImageCap(head.Drawings[index].Caption);
  };

  let imgurl =
    'https://www.texacone.com/IECHI/content/images/heads/' + galImage;
  let otherImages = head.Drawings.map((drawing, index) => {
    let smallimgurl =
      'https://www.texacone.com/IECHI/content/images/heads/' + drawing.FileName;
    return (
      <img
        src={smallimgurl}
        alt={drawing.Title}
        className={styles.smallImg}
        key={drawing.Title + index}
        onClick={() => changeImage(index)}
      />
    );
  });

  // Note types here currently for referral to how notes are categorized in database.
  // let noteTypes = {
  //   1: 'Cylinder Notes',
  //   2: 'Packing Notes',
  //   3: 'Internal Head Notes',
  //   4: 'Changes to system',
  //   5: 'Manufacturer Id',
  // }

  let cylnotes = head.Notes.filter(note => {
    return note.Type === 1;
  }).map(note => {
    return <li key={note.Id}>{note.Note}</li>;
  });

  let packingSets = head.PackingSetNumbers.map(packer => {
    return (
      <tr key={packer.Id}>
        <td>{packer.PlungerDiameterDisplay}</td>
        <td>{packer.Type}</td>
        <td>{packer.NomDisplay}</td>
        <td>{packer.PlungerGroupName}</td>
        <td>{packer.PackingSetNumber}</td>
      </tr>
    );
  });

  let packnotes = head.Notes.filter(note => {
    return note.Type === 2;
  }).map(note => {
    return <li key={note.Id}>{note.Note}</li>;
  });

  let headnotes = head.Notes.filter(note => {
    return note.Type === 3;
  }).map(note => {
    return <li key={note.Id}>{note.Note}</li>;
  });

  let troublenotes = head.Notes.filter(note => {
    return note.Type === 3;
  }).map(note => {
    return <li key={note.Id}>{note.Note}</li>;
  });

  let packings = head.PackingSetContents.map(item => {
    let imgurl =
      'https://www.texacone.com/IECHI/content/images/contents/' + item.FileName;
    let printname = item.PrintedName;
    return (
      <div key={item.Id}>
        <img src={imgurl} alt={printname} />
        <p>{printname}</p>
      </div>
    );
  });

  let alternateSets = head.Recommendations.map(recommendation => {
    let recoimage =
      'https://www.texacone.com/IECHI/content/images/icons/' +
      recommendation.RecommendationType.FileName;
    return (
      <tr key={recommendation.Id}>
        <td>{recommendation.RecommendationType.Name}</td>
        <td>
          <img src={recoimage} alt={recommendation.RecommendationType.Name} />
        </td>
        <td>{recommendation.Recommendation}</td>
      </tr>
    );
  });

  return (
    <React.Fragment>
      <ScrollToTop />
      {isLogged ? (
        <>
          <div className={styles.filterBack} onClick={props.closeHead}>
            <p className={styles.backButton}>Go Back</p>
          </div>
          <h1 className={styles.titleName}>{head.Name}</h1>
          <div className={styles.headImageWrapper}>
            <div className={styles.images}>
              <h3>Gallery</h3>
              <img className={styles.heroImage} src={imgurl} alt={imageAlt} />
              <div className={styles.caption}>
                <p>{imageCap}</p>
              </div>
              <div className={styles.otherImages}>{otherImages}</div>
            </div>
          </div>
          <div className={styles.headDataWrapper}>
            <h3 className={styles.wrapperTitle}>Cylinder Head Info</h3>
            {cylnotes.length > 0 ? <CylNotes data={cylnotes} /> : null}
            <PackInfo data={packingSets} />
            {packnotes.length > 0 ? <PackNotes data={packnotes} /> : null}
            {/* Head notes removed from view as these are supposed to be admin only although
          they are visible in the public API */}
            {/* { (headnotes.length > 0) ? <HeadNotes data={headnotes} /> : null } */}
            <PackCont data={packings} />
            {alternateSets.length > 0 ? <PackAlt data={alternateSets} /> : null}
            <Locations data={head} />
            {troublenotes.length > 0 ? (
              <TroubleNotes data={troublenotes} />
            ) : null}
          </div>
        </>
      ) : (
        <div className={styles.unLoggedDataWrapper}>
          <div className={styles.unLogged}>
            <div className={styles.filterBack} onClick={props.closeHead}>
              <p className={styles.backButton}>Go Back</p>
            </div>
            <h1>You must be logged in to view head data.</h1>
            <h3>
              Click <Link to="/login">HERE</Link> to log in.
            </h3>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default headdata;
