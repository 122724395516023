import React from 'react';
import styles from './ei_selection.module.scss';

const ei_selection = (props) => {

  let cardHeaders = props.cardHeaders.map(card => {
    return(
      <li key={card}><a href={'#' + card}>{card}</a></li>
    )
  });
  return (
    <div className={styles.toolMenu}>
      <ul>
        {cardHeaders}
      </ul>
    </div>
  );
};

export default ei_selection;