import React from 'react';
import { graphql, Query } from 'react-apollo';
import gql from 'graphql-tag';
import Spinner from '../../../../components/spinner/spinner';
import MarkdownViewer from '../../../../components/MarkdownViewer';
import styles from './history.module.scss';

const COMPONENT_QUERY = gql`query sectionComponents {
  components (where: {component_name: "about_us_history" }) {
    textfield1
    media1 {
      url
      name
    }
  }
}`;

const historySection = () => (
  <Query query={COMPONENT_QUERY}>
    {({ loading, error, data }) => {
      if (loading) return <Spinner />
      if (error) return `Error! ${error.message}`;
      let imgurl = "https://anchor-api.texacone.com" + data.components[0].media1.url
      return (
        <div className={styles.contentWrapper}>
          <img src={imgurl} alt={data.components[0].media1.name}/>
          <div className={styles.markdownText}>
            <MarkdownViewer md={data.components[0].textfield1} />
          </div>
        </div>
      );
    }}
  </Query>
);


export default graphql(COMPONENT_QUERY)(historySection);