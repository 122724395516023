import React from 'react'
import styles from './custradio.module.scss'

const custradio = props => {
  return (
    <>
      <input
        type="radio"
        id={props.unique}
        name="drop_ship"
        value={props.value}
        ref={props.register}
      />
      <label htmlFor={props.unique}>
        <span className={styles.radiotext}>{props.label}</span>
      </label>
    </>
  )
}

export default custradio
