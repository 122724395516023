import React from 'react';
import { css } from '@emotion/core';
import { BounceLoader } from 'react-spinners';

const override = css`
    width: 75%;
    position: absolute;
    top: 50%;
    display: block;
    margin: 20px auto;
    border-color: green;
`;

const spinner = () => {
  return (
    <div className='sweet-loading'>
        <BounceLoader
          css={override}
          color={'rgba(126, 211, 33, 1)'}
        />
      </div>
  );
};

export default spinner;