import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './filtersection.module.scss'

const filtersection = (props) => {

  let cx = classNames.bind(styles);

  const [open, setOpen] = useState(false);

  let sectionClass;
  let arrowClass;
  if (open) {
    arrowClass = cx({
      arrowOpen: true,
    });
    sectionClass = cx({
      filterBoxes: true,
      open: true
    });
  } else {
    arrowClass = cx({
      arrowClose: true,
    });
    sectionClass = cx({
      filterBoxes: true,
    });
  }

  let handleFilter = () => {
    if (!open) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }



  return (
    <div className={styles.filterSection} onClick={handleFilter}>
        <h1 className={arrowClass}>{props.head}</h1>
        <div className={sectionClass}>
          {props.filters}
        </div>
    </div>
  );
};

export default filtersection;