import React, { Component } from 'react';
import {Query} from 'react-apollo';
import Spinner from '../../components/spinner/spinner';
import gql from 'graphql-tag';
import classNames from 'classnames/bind';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import About from './sections/about/about';
import News from './sections/news/news';
import History from './sections/history/history';
import Team from './sections/team/team';
import { Helmet } from 'react-helmet';
import styles from './AboutUs.module.scss';
export default class AboutUs extends Component {

  cx = classNames.bind(styles);

  state = {
    currentSelection: 'About',
    buttons: ['About', 'News', 'History', 'Team'],
  }



  sectionComponent = (data) => {
    switch(this.state.currentSelection) {
      case 'About':
        return <About data={data} />;
      case 'News':
        return <News />;
      case 'History':
        return <History />;
      case 'Team':
        return <Team />;
      default:
        return <About />;
      }
  }

  clickChange = (name) => {
    this.setState({currentSelection: name})
  }



  componentDidMount() {
    if (this.props.match.params.subsection) {
      let upperName = this.props.match.params.subsection.charAt(0).toUpperCase() + this.props.match.params.subsection.slice(1);
      this.clickChange(upperName);
    }

  }


  render() {

    const SECTIONS_QUERY = gql`query componentsData {
      sections (where: {section_name: about_us}) {
        components {
          component_name
          textfield1
          media1 {
            url
            name
          }
        }
      }
    }`;





    return (

    <Query query={SECTIONS_QUERY}>
      {({ loading, error, data }) => {
        if (loading) return <Spinner />
        if (error) return `Error! ${error.message}`;
        let buttons = this.state.buttons.map(button => {
          let pStyle;
          if (this.state.currentSelection === button) {
            pStyle = this.cx({
              selected: true,
            })
          } else {
            pStyle = this.cx({
              selected: false
            })
          }
          return (
            <div key={button} className={styles.menuItem} onClick={() => this.clickChange(button)}>
              <p className={pStyle}>{button}</p>
            </div>
          );
        });


        // WIP to call components based on returned GQL data

        // const section_names = {}

        // data.sections.forEach(section => {
        //   let section_name = section.section_name;
        //   let upper_name = section_name.charAt(0).toUpperCase() + section_name.slice(1);
        //   let section_value = {upper_name}
        //   section_names[section_name] = section_value;
        // })



        // const sections = data.sections.map(section => {
        //   const Section = section_names[section.section_name];
        //   return(
        //     <Section />
        //   )
        // });


        return (
        <div className={styles.mainWindow}>
          <Helmet>
            <title>Texacone | Specializing In Products & Service That Go Above And Beyond</title>
            <meta name="description" content="Texacone is a third generation family business supplying custom-designed products to the elevator industry and other industries." />
          </Helmet>
          <ScrollToTop />
          <div className={styles.fullElevator}>
            <div className={styles.menuWrapper}>
              <div className={styles.leftDoor}>
                <div className={styles.leftPanel}></div>
              </div>
              <div className={styles.menuBar}>
              {buttons}
              </div>
              <div className={styles.rightDoor}>
                <div className={styles.rightPanel}></div>
              </div>
            </div>
          </div>


          {this.sectionComponent(data)}
        </div>




        );
      }}
    </Query>
    )
  };
}

