import React from 'react';
import classNames from 'classnames/bind';
import styles from './checkbox.module.scss';

let cx = classNames.bind(styles);

let checkClass = cx({
  control: true,
  'control--checkbox': true,
  labelText: true,
});

const checkbox = (props) => {
  return (
    <React.Fragment>
      <label className={checkClass}>{props.label}
        <input id={props.unique} type="checkbox" name={props.label} value={props.value} onClick={props.boxchecked} />
        <div className={styles.control__indicator}></div>
        </label>
    </React.Fragment>
  );
};

export default checkbox;


