import React from 'react';
import classNames from 'classnames/bind';
import MarkdownViewer from '../../../../../components/MarkdownViewer';
import styles from './newspost.module.scss';

let cx = classNames.bind(styles);


const newsPost = (props) => {

  let newsClass;
  if (props.index % 2 === 0) {
    newsClass = cx({
      imageLeft: true,
      newsInnerDiv: true
    });
  } else {
    newsClass = cx({
      imageRight: true,
      newsInnerDiv: true
    });
  }
  let imgurl = "https://anchor-api.texacone.com" + props.img;

  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
  ];

  let corrected_date = new Date(props.post_data.dateposted);
  let month_name = monthNames[corrected_date.getMonth()];
  let month_date = corrected_date.getDate();
  let post_date = `${month_name} ${month_date}`;
  return (
    <div className={styles.postWrapper}>
      <div className={newsClass}>
        <img src={imgurl} alt={props.name} />
        <h1>{props.post_data.title}</h1>
        <MarkdownViewer md={props.post_data.description} />
      </div>
      <div className={styles.postDate}>
        <p>Posted</p>
        <p>{post_date}</p>
      </div>
    </div>
  );
};

export default newsPost;