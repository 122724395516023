import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './headnotes.module.scss';

let cx = classNames.bind(styles);


const headnotes = (props) => {


  const [isOpen, setOpen] = useState(false);

  let arrowClass;
  if (isOpen) {
    arrowClass = cx({
      arrowOpen: true,
    });
  } else {
    arrowClass = cx({
      arrowClose: true,
    });
  }

  let headnoteHandler = (e) => {
    setOpen(!isOpen);
  }

  return (
      <div className={styles.collapseData}>
        <h3 onClick={headnoteHandler} className={arrowClass}>Head Notes</h3>
        { isOpen ?
          <div className={styles.dataDiv}>
            <ul>
              {props.data}
            </ul>
          </div>
          : null
        }
      </div>
  );
};

export default headnotes;