import React, { useState, useEffect } from 'react';
import styles from './head.module.scss';

const head = (props) => {
  let head = props.data;
  let totalData = props.totalData;
  let index = props.index;
  const [img, setImg] = useState(0);

  useEffect(
    () => {
      setImg("https://www.texacone.com/IECHI/content/images/heads/" + head.FileName)
    }
  )




  return (
    <div className={styles.headCell} onMouseEnter={() => props.hoverHead(props.name)} onMouseLeave={() => props.hoverHead('Cylinder Head')} onClick={() => props.openHead(totalData,index)}>
      <img src={img} alt={head.Id} />
      <div className={styles.pTag}>
        <p>{props.name}</p>
      </div>
    </div>
  );
};

export default head;